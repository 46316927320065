import axios from "axios";
// import { utils } from "./utis";
import { ElMessage } from 'element-plus'
import HTTP_API_URL from "@/config/env.js"
import router from '../router/index';

const Authorization = 'Z2dlcjZ1eDNycTdxanEycXZjcGpiYXFyOnZiYWRzcGQ4cHRlbXlpcXZrd2djYXJoYzg5ZWtzaTR1'

export const uploadApi = axios.create({
  // baseURL: "http://www.youyouzhenxuan.com" + "/official",
  // baseURL: "http://121.41.66.8/api",
  baseURL: HTTP_API_URL,
  timeout: 30000,
  headers: {
    "Authorization": Authorization,
  },
  //跨域请求发送cookie
  withCredentials: true,
});

// 航线
export const axiosBase = axios.create({
  timeout: 10000,  // 设置请求超时时间
});

// 响应拦截器：可以统一处理响应和错误
axiosBase.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error && error.response) {
      // console.log()
      switch (error.response.status) {
        case 400:
          ElMessage.error(error.response.data.msg)
          break;
        case 401:
          refreshTokenFn(error.config)
          break;
      }
    } else {
      if (error.response?.status == -1) {
        return ElMessage.error(error?.response?.data?.errorMsg)
      }
      return ElMessage.error(error?.response?.data?.msg) || ElMessage.error('接口访问失败')
    }
  }
);


const instance = axios.create({
  // baseURL: "http://www.youyouzhenxuan.com" + "/official",
  // baseURL: "http://121.41.66.8/api",
  baseURL: HTTP_API_URL,
  timeout: 30000,
  //transformRequest在向服务器发送前，修改请求数据
  transformRequest: [
    function (data) {
      return JSON.stringify(data);
    },
  ],
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Authorization": Authorization,
  },
  //跨域请求发送cookie
  withCredentials: true,
});

instance.interceptors.request.use(
  function (config) {
    // console.log(config, "con");
    const msg = JSON.parse(sessionStorage.getItem('uavmsg'))
    if (msg) {
      const tenantId = msg.tenantId
      const token = msg.token
      config.headers.Token = token
      config.headers.tenantId = tenantId
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
)

uploadApi.interceptors.request.use(
  function (config) {
    const msg = JSON.parse(sessionStorage.getItem('uavmsg'))
    if (msg) {
      const tenantId = msg.tenantId
      const token = msg.token
      config.headers.Token = token
      config.headers.tenantId = tenantId
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
  // 未完善
  function (response) {
    let code = Number(response.data?.code) || 200
    if (code == 200 ) {
      return response.data;
    } else if (code === 401) {
      refreshTokenFn(response.config)
    } else {
      return ElMessage.error(response.data.msg)
    }
  },
  function (error) {
    if (error && error.response) {
      // console.log()
      switch (error.response.status) {
        case 400:
          ElMessage.error(error.response.data.msg)
          break;
        case 401:
          refreshTokenFn(error.config)
          // 登录超时
          // 退出登录 清空信息
          // sessionStorage.removeItem('uavmsg')
          // sessionStorage.removeItem('workspace_id')
          // sessionStorage.removeItem('employeeId')
          // localStorage.removeItem('username')
          // router.push('/login')
          break;
        // case 403:
        //   error.message = "拒绝访问";
        //   break;
        // case 405:
        //   error.message = "请求方法未允许";
        //   break;
        // case 500:
        //   error.message = "服务器内部出错";
        //   break;
      }
    } else {
      if (error.response?.status == -1) {
        return ElMessage.error(error?.response?.data?.errorMsg)
      }
      return ElMessage.error(error?.response?.data?.msg) || ElMessage.error('接口访问失败')
    }
    // return Promise.reject(error);
  }
)

uploadApi.interceptors.response.use(
  function (response) {
    if (response.data?.code == 0) {
      return response.data;
    } else {
      return ElMessage.error(response.data.msg)
    }
  },
  function (error) {
    console.log(error, "11111");
    if (error && error.response) {
      // console.log()
      switch (error.response.status) {
        case 400:
          ElMessage.error(error.response.data.msg)
          break;
        // case 403:
        //   error.message = "拒绝访问";
        //   break;
        // case 405:
        //   error.message = "请求方法未允许";
        //   break;
        // case 500:
        //   error.message = "服务器内部出错";
        //   break;
      }
    } else {
      return ElMessage.error(error?.response?.data?.msg) || ElMessage.error('接口访问失败')
    }
    // return Promise.reject(error);
  }
)

export const liveRequest = axios.create({
  baseURL: HTTP_API_URL,
  timeout: 30000,
  transformRequest: [
    function (data) {
      return JSON.stringify(data);
    },
  ],
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Authorization": Authorization,
  },
  withCredentials: true,
});
liveRequest.interceptors.request.use(
  function (config) {
    const msg = JSON.parse(sessionStorage.getItem('uavmsg'))
    if (msg) {
      const tenantId = msg.tenantId
      const token = msg.token
      config.headers.Token = token
      config.headers.tenantId = tenantId
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
liveRequest.interceptors.response.use(
  function (response) {
    console.log('[ response.data.msg ] >', response.data.msg)
    return response.data
  },
  function (error) {
    console.log(error);
  }
);

// 发送请求获取新的访问令牌
const refreshTokenFn = async (data) => {
  const originalRequest = data;
  try {
    const uavmsg = JSON.parse(sessionStorage.getItem('uavmsg'));
    const params = new URLSearchParams();
    params.append('refreshToken', uavmsg.refreshToken);
    const { data } = await axios.post(HTTP_API_URL + '/anyone/refreshToken', params.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
    });
    uavmsg.token = data.data.token
    uavmsg.refreshToken = data.data.refreshToken
    // 更新本地存储的访问令牌
    sessionStorage.setItem('uavmsg', JSON.stringify(uavmsg))
    // 重新发送原始请求
    originalRequest.headers.Authorization = `Bearer ${data.data.token}`;
    return service(originalRequest);
  } catch (error) {
    // 处理刷新令牌失败，可能需要用户重新登录
    console.error('Failed to refresh access token', error);
    sessionStorage.removeItem('uavmsg')
    sessionStorage.removeItem('workspace_id')
    sessionStorage.removeItem('employeeId')
    sessionStorage.removeItem('username')
    return router.push('/login');
    // 清除用户信息，跳转到登录页等处理
    // 这里视具体情况处理
  }
};

export default instance;
